// Import all plugins matching the pattern using Vite's import.meta.glob
const plugins = import.meta.glob('./plugin/*.js');

// Function to dynamically load all plugins
async function loadPlugins() {
    for (const path in plugins) {
        try {
            const plugin = await plugins[path](); // Call the function to load the plugin
            if (plugin.default) {
                plugin.default(); // Call the default export if available
            }
        } catch (error) {
            console.error(`Failed to load plugin: ${path}`, error);
        }
    }
}

// Export a promise that resolves when all plugins are loaded
export const pluginsLoaded = loadPlugins();
