import { pluginsLoaded } from '../plugins.js';

window.SMALLSCREEN = false;

(async () => {
    // Wait for all plugins to be loaded
    await pluginsLoaded;

    window.app = window.app || {};
    window.app.main = (function() {

        var inited = false;

        // entry point
        function init() {

            if (inited) {return false;}

            checkSmallScreen();

            if (SMALLSCREEN) {
                $('body').addClass('mobile');
            }

            browserDetect();
            window.app.pageHandler.init();
            window.app.popupHandler.init();
            window.app.menuHandler.init();

            inited = true;
        }

        function checkSmallScreen() {
            window.SMALLSCREEN = $('body').getPseudoContent('::before').toLowerCase() === 'smallscreen';
        }

        function getQueryStringValue(key) {
            return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        }

        function browserDetect() {
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
            isSafari = isSafari || (navigator.userAgent.indexOf("afari") >= 0 && navigator.userAgent.indexOf("hrome") == -1);
            if (isSafari) {
                $('html').addClass('safari');
            }
        }

        return {
            init: init,
            getQueryStringValue: getQueryStringValue
        };
    })();

    $(document).ready(function() {
        window.app.main.init();
    });

})();
